@use '@/styles/utils/mixins.scss' as *;

.footerWrapper {
  background-color: $white;
  margin-top: 12.5rem;
  position: relative;
  @include tab() {
    padding: 1.875rem 0rem;
    border-top-left-radius: 1.25rem;
    border-top-right-radius: 1.25rem;
  }

  .topNav {
    background-color: $neutral02;
    padding: 1.25rem 0;
    @include tab() {
      background-color: $white;
    }
    &Container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      @include tab() {
        flex-direction: column;
        .brandLogo {
          display: none;
        }
        .socialList {
          display: none;
        }
      }
    }
  }
  .contactList {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    margin: 0;
    padding: 0;
    gap: 1rem;
    list-style: none;
    font-size: 0.675rem;
    text-transform: uppercase;
    color: $primary;
    @include tab() {
      align-items: flex-start;
      width: 100%;
    }
    li {
      display: flex;
      align-items: center;
      gap: 0.75rem;
      font-weight: 400;

      @include tab() {
        align-items: flex-start;
        gap: 0.75rem;
        flex-direction: column;
        height: 100%;
      }
    }
    a {
      font-weight: 600;
      font-size: 1rem;
      color: $dark;
      &:hover,
      &:focus,
      &:active {
        color: $primary;
      }
      @include tab() {
        margin-top: auto;
      }
    }
  }
  .socialList {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    gap: 1.875rem;
    list-style: none;

    .socialBtn {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 0.875rem;
      width: 0.875rem;

      &:hover,
      &:focus,
      &:active {
        opacity: 0.8;
      }
    }
  }
  .bottomNavSection {
    padding: 1.25rem 0;
    @include tab() {
      padding: 0;
    }
  }
  .bottomContainer {
    display: flex;
    gap: 3rem;
    @include tab() {
      flex-direction: column;
    }
  }
  .bottomLeftContent {
    flex: 1 1 auto;
  }
  .copyrightSection {
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
    padding: 3rem 0;
    @include tab() {
      padding-top: 1.875rem;
      margin-top: 3rem;
      border-top: 0.063rem solid $primaryAlt;
    }
    .copyrightDescription {
      font-size: 0.875rem;
      max-width: 27rem;
    }
    .copyrightLinkList {
      display: flex;
      list-style: none;
      margin: 0;
      padding: 0;
      gap: 2.5rem;
      @include tab() {
        flex-direction: column;
        gap: 1.25rem;
      }
      .copyrightLink {
        font-size: 0.875rem;
        font-weight: 500;
        color: $dark;
        &:hover,
        &:focus,
        &:active {
          color: $primary;
        }
      }
    }
    .copyrightContent {
      color: $neutral08;
      font-size: 0.875rem;
    }
    .footerBrandContainer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .brandLogo {
        width: auto;
        height: 3.5rem;
        @include tab() {
          height: 1.875rem;
        }
      }
    }
  }

  .desktopNavigation {
    display: block;
    @include tab() {
      display: none;
    }
  }

  .mobileNavigation {
    display: none;
    @include tab() {
      display: block;
    }
  }

  .backToTopBtn {
    position: fixed;
    bottom: -4rem;
    right: 1.5rem;
    background-color: $transparent;
    color: $white;
    border: none;
    cursor: pointer;
    padding: 0;
    height: 3rem;
    width: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    opacity: 0;
    transition: all 0.3s ease;
    z-index: 999;

    &.show {
      opacity: 1;
      bottom: 4.5rem;
    }

    &:hover {
      opacity: 0.8;
    }

    &:hover,
    &:active,
    &:focus,
    &:focus-visible,
    &:focus-within {
      background: transparent !important;
      outline: 0 !important;
      box-shadow: none !important;
    }
  }
}
